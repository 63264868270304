const burgerMenu = document.querySelector(".menu-opener");
const closeBtn = document.querySelector(".close-menu");
const mobileNav = document.querySelector("nav");
const htmlEl = document.q;

burgerMenu.addEventListener("click", function () {
    mobileNav.classList.add("is-open");
});

closeBtn.addEventListener("click", function () {
    mobileNav.classList.remove("is-open");
});
